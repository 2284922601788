class RequestMonitorService {
  constructor(client) {
    this.apiClient = client;
  }

  async getRequestedTickets(entityId, cancelToken) {
      return this.apiClient.get(`requestmonitor/${entityId}`,cancelToken);
    }
}

export default RequestMonitorService;
