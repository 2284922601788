class GroupContractService {
  constructor(client) {
    this.apiClient = client;
  }

  async getGroupContractAccessHolders(
    facilityID,
    contractID,
    searchWithinContract,
    limit,
    offset,
    searchTerm
  ) {
    return await this.apiClient.get(
      `groupcontracts/accessHolders/${facilityID}/${contractID}`,
      {
        params: { searchWithinContract, limit, offset, searchTerm },
      }
    );
  }

  async getGroupContractAccessHoldersByCategory(
    facilityID,
    contractID,
    searchWithinContract,
    limit,
    offset,
    searchTerm,
    searchCategory
  ) {
    return await this.apiClient.get(
      `groupcontracts/accessholdersbycategory/${facilityID}/${contractID}`,
      {
        params: { searchWithinContract, limit, offset, searchTerm, searchCategory },
      }
    );
  }

  async addAccessHoldersToGroupContract(
    facilityID,
    contractID,
    accessHolderIDs
  ) {
    return await this.apiClient.put(
      `groupcontracts/accessholders/${facilityID}/${contractID}`,
      accessHolderIDs
    );
  }

  async removeAccessHolderFromGroupContract(contractID, accessHolderID) {
    return await this.apiClient.delete(
      `groupcontracts/accessholders/${contractID}/${accessHolderID}`
    );
  }

  async createGroupContract(contract) {
    return await this.apiClient.post("groupcontracts", contract, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateGroupContract(contract) {
    return await this.apiClient.put("groupcontracts", contract, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getGroupContract(facilityID, contractID) {
    return await this.apiClient.get(
      `groupcontracts/${facilityID}/${contractID}`
    );
  }

  async getGroupContractSlim(contractID) {
    return await this.apiClient.get(
      `groupcontracts/${contractID}/slim`
    );
  }

  async getGroupContracts(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get(`groupcontracts/${entityID}`, {
      params: { limit, offset, searchTerm },
    });
  }

  async deleteGroupContract(entityID, contractID) {
    return await this.apiClient.delete(
      `groupcontracts/${entityID}/${contractID}/delete`
    );
  }
}

export default GroupContractService;
