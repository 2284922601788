class ValetParkingService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async validateArrivalTicketNumber(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/validate/${ticketNumber}`);
  }

  async getDetailsByTicketNumber(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/guestType/${ticketNumber}`);
  }

  async createValetArrival(arrivalData) {
    return await this.apiClient.post(
      `valetparking/arrival`,
      arrivalData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async submitRetrieval(retrievalData) {
    return await this.apiClient.put(
      `valetparking/updateretrieval`,
      retrievalData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async validateRequestTicketNumber(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/validatearrival/${ticketNumber}`);
  }

  async validateRetrievalTicketNumber(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/validateretrieval/${ticketNumber}`);
  }

  async getArrivalDetails(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/arrivaldetails/${ticketNumber}`);
  }

  async updateValetArrival(arrivalData) {
    return await this.apiClient.put(
      `valetparking/UpdateArrival`,
      arrivalData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async updateValetRequest(requestData) {
    return await this.apiClient.put(
      `valetparking/updaterequest`,
      requestData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getTicketDetails(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/${ticketNumber}/details`);
  }

  async validateDeliveryTicketNumber(entityID, ticketNumber) {
    return this.apiClient.get(`valetparking/${entityID}/validatedelivery/${ticketNumber}`);
  }

  async updateValetDelivery(deliveryData) {
    return await this.apiClient.put(
      `valetparking/updatedelivery`,
      deliveryData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
}

export default ValetParkingService;
