import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import apiClient from "../../auth/apiClient";
import RequestMonitorService from "../../services/RequestMonitorService";
import _ from "lodash";

export const requestAdapter = createEntityAdapter({
  initialState: [],
});

const initialState = {
  requests: [],
};

const requestMonitorService = new RequestMonitorService(apiClient);

const requestMonitorSlice = createSlice({
  name: "requestMonitor",
  initialState,
  reducers: {
    loadRequests: (state, action) => {
      state.requests = action.payload;
    },
  },
});

export const { loadRequests } = requestMonitorSlice.actions;

export default requestMonitorSlice.reducer;

export const fetchRequestsByEntityID = (entityID) => async (dispatch) => {
  try {
    let response = await requestMonitorService.getRequestedTickets(entityID);

    dispatch(loadRequests(response.data || []));
  } catch (err) {
    console.error(err);
  }
};
