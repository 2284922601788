export default class ScheduledReportsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getScheduledReports(facilityID) {
    return await this.apiClient.get(`scheduledreports/${facilityID}`);
  }

  async getOptionsForScheduledReports() {
    return await this.apiClient.get(`scheduledreports/options`);
  }

  async createScheduledReport(data) {
    return await this.apiClient.post("scheduledreports", data);
  }

  async updateScheduledReport(data) {
    return await this.apiClient.put("scheduledreports", data);
  }

  async updateEnabledScheduledReport(data) {
    return await this.apiClient.put("scheduledreports/enabled", data);
  }

  async deleteScheduledReport(scheduledrptid) {
    return await this.apiClient.delete(`scheduledreports/${scheduledrptid}`);
  }

}

