class LPRService {
	constructor(client) {
		this.apiClient = client;
	}

	async getCaptureImage(lprId) {
		return this.apiClient.get(`reads/${lprId}/captureimage`, {
			responseType: "blob",
		});
	}

	async updateCameraRead(lprID, cameraRead) {
		return await this.apiClient.put("reads/update/cameraread", {
			lprID,
			cameraRead
		});
	} 
}
export default LPRService;