class CubicCertificateService {
  constructor(client) {
    this.apiClient = client;
  }

  async GetCubicCertificates(includeInActive) {
    return await this.apiClient.get(
      `cubic-certificates?includeInActive=${includeInActive}`
    );
  }

  async CreateCertificates(certificates) {
    return await this.apiClient.post("/cubic-certificates", certificates, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async UpdateCertificate(certificates) {
    return await this.apiClient.put("/cubic-certificates", certificates, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async DeleteCetificate(certificateId) {
    return await this.apiClient.delete(`/cubic-certificates/${certificateId}`);
  }
    
}

export default CubicCertificateService;
