class ValidationUserOnePassService {
  constructor(client) {
    this.apiClient = client;
  }

  async createAndSendOnePass(data) {
    return await this.apiClient.post(`onepass/createonepass`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async convertTicketToOnePassAndSend(data) {
    return await this.apiClient.post(`onepass/converttoonepass`, data, {
      headers: {
        'Content-Type': 'application/json' 
      }
    });
  }
  
  async sendCredentials(request) {
    return await this.apiClient.post(`onepasscredential/sendCredentials`, request,
       {headers: {
        "Content-Type": "application/json"
      }
    });
}

}

export default ValidationUserOnePassService;