import {
    createSlice,
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit';
import _ from "lodash";
import isEqual from "lodash/isEqual";
import apiClient from "../../../auth/apiClient";


const orgTreeAdapter = createEntityAdapter({
    selectId: (entity) => entity.entityid
});

export const loadOrgEntities = (entityID, federatedId) => async (dispatch) => {
    apiClient
        .get(`entities/${federatedId}/tree/byuser`)
        .then((resp) => {
            dispatch(setOrgTree({ entityID, entities: resp.data })); //load the full entity tree into EntityList
        })
        .catch((err) => {
            console.error(
                "Encountered error while loading all entities from context:",
                err
            );
        })
        .finally(() => {
            //do nothing, getting IDs next
        });
};


const orgTreeSlice = createSlice({
    name: "orgTree",
    initialState: orgTreeAdapter.getInitialState(),
    reducers: {
        setOrgTree: (state, action) => {
            orgTreeAdapter.setAll(state, action.payload.entities);
        },
        updateOrgTree: (state, action) =>  {
            const {entities, childrenPermissions} = action.payload;
            let newEntities =  entities.filter((entity) => {
                if (entity.entitytype === 1 && !entity.parententityid && childrenPermissions[entity.entityid] == null) {
                    return false;
                }
                if (state.entities[entity.entityid] && state.entities[entity.entityid].permissions) {
                    return false;
                }
                return true;
            }).map((entity) => ({
                ...entity,
                permissions: childrenPermissions[entity.entityid] ?? entity.permissions
            }));
            orgTreeAdapter.upsertMany(state, newEntities);
        },
        setNewOrgTree: (state, action) => {
            const {contextID, entities , permissions } = action.payload;
            if(entities.length <= 0) {
                return
            }
            let newEntities = [...entities]
            newEntities.forEach((entity) => {
                if(entity.entityid === contextID){
                    entity.permissions =       [
                        ...permissions[0].permissions?.map(
                            (permission) => permission.permissionName
                        ),
                        ...permissions[0].groups
                            ?.map((group) => [...group.permissions])
                            .flat(),
                    ] ?? [];
                }
            })
            // remove old tree
            orgTreeAdapter.removeAll(state);

            // set New Tree
            orgTreeAdapter.setAll(state, newEntities)
        },
        setNewNode: (state, action) => {
                orgTreeAdapter.addOne(state, action.payload);
        },
        removeAllNodes: (state) => {
            orgTreeAdapter.removeAll(state);
        },
        updateOrgEntity: (state, action) => {
            const updatedEntity = action.payload;
            const entityId = updatedEntity.entityid;

            if (!entityId) {
                console.error("No valid entity ID found in the payload:", updatedEntity);
                return;
            }

            const existingEntity = state.entities[entityId];

            existingEntity.name = action.payload.name;
            existingEntity.details = action.payload.details;
            existingEntity.settings = action.payload.settings;
        }
    }
});

const OrgTreeSelectors = orgTreeAdapter.getSelectors((state) => state.orgTree);

export const {
    selectById: selectOrgTreeEntityById, // Retrieve a single entity by its ID
    selectAll: selectAllOrgTreeEntities, //Retrieve all entities as an array
} = OrgTreeSelectors;

export const selectOrgRawIDsWithScope = createSelector(
    selectAllOrgTreeEntities,
    (entities) => {
        return entities.map((entity) => ({
            entityid: entity.entityid,
            parententityid: entity.parententityid || null, // Return null if no parentEntityId;
            name: entity.name,
        }));
    }
);
export const selectEntities = (state) => state.orgTree.entities;
const attachChildren = (entities, entity) => {
    // Find the children of the current entity
    const children = Object.values(entities)
        .filter(
            (child) =>
                child.parententityid === entity.entityid
        )
        .map((child) => attachChildren(entities, child)); // Recursively attach children to each child

    return {
        ...entity,
        children,
    };
};
export const selectSpecificEntitiesWithChildren = (specificEntities) =>
    createSelector([selectEntities], (entities) => {
        return specificEntities.map((entity) => attachChildren(entities, entity));
    });

export const selectAllOrgFacilities = createSelector(
    selectAllOrgTreeEntities,
    (entities) => entities.filter((entity) => entity.type === "Facility")
);

export const selectAllOrgScopedPermissions = createSelector(
    (state) => state.orgTree, // Access the normalized state slice
    (coreEntities) => {
        const result = {};

        // Iterate through the IDs to access each entity
        coreEntities.ids.forEach((id) => {
            const entity = coreEntities.entities[id];
            if (entity && entity.permissions && entity.permissions.length > 0) {
                result[id] = entity.permissions
            }
        });

        return result;
    }
);

export const selectOrgEntitiesWithNoState = createSelector(
    (state) => state.orgTree, // Access normalized entities
    (entities) => {
        const result = {};
        for (const [key, entity] of Object.entries(entities.entities)) {
            result[key] = {
                entityId: entity.entityid,
                parentEntityId: entity.parententityid || null,
                typeId: entity.typeid,
            };
        }
        if (!selectOrgEntitiesWithNoState.previousResult) {
            selectOrgEntitiesWithNoState.previousResult = result;
            return result;
        }

        // Return the previous result if nothing has changed
        if (isEqual(result, selectOrgEntitiesWithNoState.previousResult)) {
            return selectOrgEntitiesWithNoState.previousResult;
        }

        selectOrgEntitiesWithNoState.previousResult = result;
        return Object.keys(result).length === 0 ? null : result;
    }
);

export const selectEntityForOrgTree = (entityId) => createSelector(
    (state) => state.orgTree.entities,
    (entities) => {
        const entity = entities[entityId];
        if(!entity){
            return null;
        }
        const hasChildren = Object.values(entities).some((e) => e.parententityid === entity?.entityid);
        return {
            parentEntityID: entity?.parententityid,
            entityID: entity?.entityid,
            name: entity?.name,
            typeID: entity?.typeid,
            hasChildren,
            lotFull:
                _.find(entity?.settings ?? [], { name: "displaylotfull" })?.value ==
                "auto"
                    ? entity?.state != null && entity?.state?.Occupancy?.Full == true //if auto, is occupancy full or not?
                    : _.find(entity?.settings ?? [], { name: "displaylotfull" })
                        ?.value == "on"
                        ? true
                        : false, //if manual, is it on or not?
            laneClosed:
                _.find(entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
                "true",
            disabled: entity?.state?.Disabled ?? false,
            deviceMode:
                _.find(entity?.settings ?? [], { name: "devicemode" })?.value ?? null,
            reverseOffline: entity?.state?.ReversingLane?.ReverseOffline,
        };
    }
);

export const makeOrgParentEntitySelector = (entityId) =>
    createSelector(
        (state) => state.orgTree, // Access the coreEntities slice
        ({ entities }) => {
            const parentEntityId = entities[entityId]?.parententityid;
            console.log("parentEntityId org", parentEntityId, entities);
            return entities[parentEntityId] ?? {};
        }
    );
export const selectOrgParentEntity = (entityId) => (state) => makeOrgParentEntitySelector(entityId)(state);
export const {
setNewNode, // might use this in the future not sure yet - JDP
updateOrgTree,
setNewOrgTree,
updateOrgEntity,
setOrgTree,
} = orgTreeSlice.actions;

export default orgTreeSlice.reducer;