
class OnePassService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async getOnePassDetails(ticketID) {
    return await this.client.get(`onepasscredential/getonepass/${ticketID}`);
  }
}

export default OnePassService;
