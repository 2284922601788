class ParkingActivityService {
  constructor(client) {
    this.apiClient = client;
  }
  async getParkingActivity(
    cancelToken,
    entityId,
    activityType,
    credentialType
  ) {
    return this.apiClient.get(`parkingactivity/${entityId}`, {
      cancelToken,
      params: { activityType, credentialType },
    });
  }

  async getParingActivityForFacilities(
    cancelToken,
    entityIDs,
    activityType,
    credentialType
  ) {
    return await this.apiClient.post(
      `parkingactivity/v2`,
      {
        EntityIDs: entityIDs,
        Activitytype: activityType,
        CredentialType: credentialType
      },
      {
        headers: { "Content-Type": "application/json" },
        cancelToken: cancelToken
      }      
    );
  }

    async getParkingActivityForValetAreas(
    cancelToken,
    entityIDs,
    activityType,
    credentialType
  ) {
    return await this.apiClient.post(
      `valetparkingactivity/v1`,
      {
        EntityIDs: entityIDs,
        Activitytype: activityType,
        CredentialType: credentialType
      },
      {
        headers: { "Content-Type": "application/json" },
        cancelToken: cancelToken
      }      
    );
  }
}

export default ParkingActivityService;
